import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import Home from './home';
// import Commission from './commission';
// import User from './user';
// import Coupon from './coupon';
import Express from './express';
import Expressed from './expressed';
import Admin from './admin';
import { Router, Route, hashHistory } from "react-router";

ReactDOM.render(
  (<Router history={hashHistory}>
    <Route path="/" component={Express}/>
    <Route path="/expressed" component={Expressed}/>
    <Route path="/admin" component={Admin}/>
  </Router>),
  document.getElementById('root')
);
