import {Component} from 'react';
import { Tabbar, NumberKeyboard } from 'react-vant';
import { WapHomeO, GoldCoinO, CouponO, Contact } from '@react-vant/icons';
import { hashHistory } from "react-router";
import Cookie from 'react-cookies';
import axios from "axios";
import constant from './constant';


export default class Footer extends Component {

  constructor() {
    super();
    this.state = {
      index: null
    }
  }

  componentDidMount() {
    if (Cookie.load(constant.token_key)) {
      this.getUserInfo();
    }
    this.setState({
      index: this.props.index
    })
  }

  getUserInfo() {
    axios.get(constant.host + "api/v2/user/info").then((res) => {
      if (res.data.base_resp.ret === -2) {
        Cookie.remove(constant.token_key);
        hashHistory.push("/");
      }
    });
  }

  hanldeChange(index) {
    if (0 === index) {
      hashHistory.push("/");
    } else if (1 === index) {
      hashHistory.push("/expressed");
    } else if (2 === index) {
      hashHistory.push("/admin");
    }
    this.setState({
      index: index
    })
  }

  render() {
    return (
      <div className="footer">
        <Tabbar fixed={true} value={this.state.index} onChange={this.hanldeChange.bind(this)}>
          <Tabbar.Item icon={<WapHomeO />}>待配送</Tabbar.Item>
          <Tabbar.Item icon={<CouponO />}>已配送</Tabbar.Item>
          <Tabbar.Item icon={<Contact />}>我的</Tabbar.Item>
        </Tabbar>
        <NumberKeyboard safeAreaInsetBottom />
      </div>
    );
  }
}
