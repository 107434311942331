import {Component} from 'react';
import { Card, Button, Dialog, Empty, Loading } from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import Cookie from 'react-cookies';
import './base.css';
import './express.css';
import axios from "axios";
import constant from "./constant";


export default class Express extends Component {

  constructor() {
    super();
    this.state = {
      requesting: false,
      items: []
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 50);
    if (!Cookie.load(constant.token_key)) {
      Dialog.alert({
        message: '当前未登录，请先登录!',
      })
    }
  }

  getData() {
    this.setState({
      requesting: true,
    })
    axios.get(constant.host + "api/v2/admin/express",
      { params: {status: 0 }}).then((response) => {
      let items = response.data.items;
      items.sort(function(a, b) {
        return (a.building + "" + a.floor) - (b.building + "" + b.floor);
      })
      this.setState({
        requesting: false,
        items: items,
      });
    });
  }

  handleClick(item) {
    let data = querystring.stringify({id: item.id, status: 1});
    axios.put(constant.host + "api/v2/admin/express", data, {headers: {"Content-Type": "application/x-www-form-urlencoded"}}).then(res =>{
      Dialog.alert({
        theme: 'round-button',
        message: (
          <div style={{ fontSize: '20px' }}>
            {item.building + "-" + item.floor + ' 配送成功'}
          </div>
        ),
      })
      this.getData();
    })
  }

  get_data() {
    let _this = this;
    if (this.state.items.length < 1 && !this.state.requesting) {
      return (
        <Empty description="暂无数据" />
      )
    }
    let items = this.state.items.map(function(item, index) {
      let contents = item.content.split('，').map(function(content_item) {
        return (
          <div>{content_item}</div>
        )
      });
      return (
        <Card key={index} round className="item">
          <Card.Header border className="item-header">{item.building}-{item.floor}</Card.Header>
          <Card.Body className="item-body">
            {contents}
          </Card.Body>
          <Card.Footer border className="item-footer">
            <Button type="primary" round size="small" onClick={_this.handleClick.bind(_this, item)}>
              确定
            </Button>
          </Card.Footer>
        </Card>
      )
    });
    return (
      <div className="items">
        {items}
      </div>
    )
  }

  render() {
    return (
      <div className="express">
        {this.state.requesting &&
          <Loading>加载中...</Loading>
        }
        {this.get_data()}
        <Footer index={0}/>
      </div>
    );
  }
}
