import {Component} from 'react';
import { Card, Button, Dialog, Empty, Cell, Field, Loading } from 'react-vant';
import { Plus } from '@react-vant/icons';
import querystring from 'querystring';
import Footer from './footer';
import Cookie from 'react-cookies';
import './base.css';
import './express.css';
import axios from "axios";
import constant from "./constant";


export default class Express extends Component {

  constructor() {
    super();
    this.state = {
      requesting: false,
      submiting: false,
      items: [],
      id: "",
      building: "",
      floor: "",
      content: ""
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getData();
    }, 50);
    if (!Cookie.load(constant.token_key)) {
      Dialog.alert({
        message: '当前未登录，请先登录!',
      })
    }
  }

  getData() {
    this.setState({
      requesting: true,
    })
    axios.get(constant.host + "api/v2/admin/express",
      { params: {status: 0 }}).then((response) => {
      let items = response.data.items;
      this.setState({
        requesting: false,
        items: items,
      });
    });
  }

  handleClick(item) {
    this.setState({
      id: item.id,
      building: item.building,
      floor: item.floor,
      content: item.content
    })
  }

  handleDeleteClick(item) {
    Dialog.confirm({
    message:
      '是否确定删除',
    }).then(() => {
      let data = querystring.stringify({id: item.id, status: 2});
      axios.put(constant.host + "api/v2/admin/express", data, {headers: {"Content-Type": "application/x-www-form-urlencoded"}}).then(res =>{
        this.setState({
          id: "",
        })
        this.getData();
      })
    }).catch(() => {
    });
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  handleSubmit() {
    if (this.state.building && this.state.floor && this.state.content && !this.state.submiting) {
      this.setState({
        submiting: true,
      })
      let building = this.state.building;
      let floor = this.state.floor;
      let content = this.state.content;
      if (this.state.id) {
        Dialog.confirm({
        message:
          '是否确定修改',
        }).then(() => {
          let data = querystring.stringify({id: this.state.id, building: building, floor: floor, room: 0, content: content});
          axios.put(constant.host + "api/v2/admin/express", data, {headers: {"Content-Type": "application/x-www-form-urlencoded"}}).then(res =>{
            this.setState({
              submiting: false,
              id: "",
              building: "",
              floor: "",
              content: ""
            })
            this.getData();
          })
        }).catch(() => {
        });
      } else {
        let data = querystring.stringify({building: building, floor: floor, room: 0, content: content});
        axios.post(constant.host + "api/v2/admin/express", data, {headers: {"Content-Type": "application/x-www-form-urlencoded"}}).then(res =>{
          this.setState({
            submiting: false,
            building: "",
            floor: "",
            content: ""
          })
          this.getData();
        })
      }
    }
  }

  get_data() {
    let _this = this;
    if (this.state.items.length < 1 && !this.state.requesting) {
      return (
        <Empty description="暂无数据" />
      )
    }
    let items = this.state.items.map(function(item, index) {
      let contents = item.content.split('，').map(function(content_item) {
        return (
          <div>{content_item}</div>
        )
      });
      return (
        <Card key={index} round className="item">
          <Card.Header border className="item-header">{item.building}-{item.floor}</Card.Header>
          <Card.Body className="item-body">
            {contents}
          </Card.Body>
          <Card.Footer border className="item-footer">
            <Button style={{marginRight: "10px"}} type="default" round size="small" onClick={_this.handleDeleteClick.bind(_this, item)}>
              删除
            </Button>
            <Button type="danger" round size="small" onClick={_this.handleClick.bind(_this, item)}>
              修改
            </Button>
          </Card.Footer>
        </Card>
      )
    });
    return (
      <div className="items">
        {items}
      </div>
    )
  }

  render() {
    return (
      <div className="express">
        <div className="item-add">
          <Field
            className="input"
            label='栋'
            placeholder='例如：3'
            value={this.state.building}
            onChange={this.handleChange.bind(this, "building")}
          />
          <Field
            className="input"
            label='房号'
            placeholder='例如：2203'
            value={this.state.floor}
            onChange={this.handleChange.bind(this, "floor")}
          />
          <Field
            rows={1}
            className="input"
            label="商品"
            type="textarea"
            placeholder="列如：西瓜 2 个，苹果 1 箱"
            value={this.state.content}
            onChange={this.handleChange.bind(this, "content")}
          />
          <Button className="button" type="danger" round={true} block={true} onClick={this.handleSubmit.bind(this)} >
            提交
          </Button>
        </div>
          {this.state.requesting &&
            <Loading>加载中...</Loading>
          }
          {this.get_data()}
        <Footer index={2}/>
      </div>
    );
  }
}
