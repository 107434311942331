import CryptoJS from "crypto-js";

let TOKEN_KEY = "token";

function get_signature(value) {
  let secret = '535a61ee10befdca388ec69b861c64f155d6ddfb5b33bc79';
  let sign = secret + ':&' + value;
  let signature = CryptoJS.MD5(sign).toString();
  return signature;
}

function get_host() {
  return window.location.protocol + "//" + window.location.host + "/";
}

export default{
  host: get_host(),
  token_key: TOKEN_KEY,
  get_signature: get_signature
}
